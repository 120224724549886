import axios from 'axios';
import { toast } from 'react-toastify';
import * as types from './types';
import { BASE_URI } from '../../config/constant';

export const loginSuccessRequest = (payload:any) => {
    return {
        type: types.LOGIN_SUCCESS,
        payload: payload,
    };
}

const logoutRequest = () => {
    return {
        type: types.LOGOUT,
        payload: {},
    };
}



export const loginSuccess = (data:any) => {
  return async (dispatch:any) => {
    dispatch(loginSuccessRequest(data));
  };
};

export const logout = () => {
  return async (dispatch:any) => {
      localStorage.clear()
      dispatch(logoutRequest());
  };
};


const updateAccessToken = (payload:any) => {
  return {
      type: "TOKEN_UPDATE",
      payload: payload,
  };
}


export const getAccessToken = (oldkey:string) => {
    return async (dispatch:any) => {
       let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BASE_URI+'get_auth_token',
          };

        axios.request(config).then((response:any) => {
            dispatch(updateAccessToken({authToken: response.data}));
        }).catch((error:any) => {
              console.log(error);
        });

    };
  }