import * as types from '../actions/types';

export interface confStateIF {
    loader: boolean;
    // fileLists: any;
    chatHistory:any;
    chatType: boolean;
}

export const initialState: confStateIF = {
    loader: true,
    // fileLists: {},
    chatHistory: {},
    chatType: false
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.INIT_APP:
            return { ...state, ...action.payload };
        // case types.UPDATE_HISTORY:
        //     return { 
        //             ...state,
        //             fileLists: action.payload.fileLists 
        //         };
        case types.UPDATE_CHAT_HISTORY:
            return { 
                    ...state,
                    chatHistory: action.payload.chatHistory 
                };
        case types.UPDATE_CHAT_TYPE:
            return { 
                    ...state,
                    chatType: action.payload.chatType 
                };
        case types.LOADING:
            return { 
                    ...state,
                    loader: action.payload.loader 
                };
        default:
            return state;
    }
};

export default reducer;
