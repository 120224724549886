import { createStore, applyMiddleware, combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';

import reducers from '../reducers';
// import { initialState } from '../reducers/AppReducer';
import sagas from '../sagas';
import { getAccessToken } from '../actions/AuthAction';



// convert object to string and store in localStorage
function saveToLocalStorage(state:any) {
    try {
      const serialisedState = JSON.stringify(state);
      localStorage.setItem("authUser", serialisedState);
    } catch (e) {
      console.warn(e);
    }
  }
  
  // load string from localStarage and convert into an Object
  // invalid output must be undefined
  function loadFromLocalStorage() {
    try {
      const serialisedState = localStorage.getItem("authUser");
      if (serialisedState === null) return undefined;
      return JSON.parse(serialisedState);
    } catch (e) {
      console.warn(e);
      return undefined;
    }
  }



// middleware that logs actions
const loggerMiddleware = createLogger({ predicate: () => false });

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
                reducers, 
                loadFromLocalStorage(),
                applyMiddleware(loggerMiddleware, thunk, sagaMiddleware)
            );
store.subscribe(() => saveToLocalStorage(store.getState()));
// // create the saga middleware
// const sagaMiddleware = createSagaMiddleware();

// const store = createStore(
//     reducers,
//     applyMiddleware(loggerMiddleware, thunk, sagaMiddleware)
// );

store.dispatch(getAccessToken(""))

// // run the saga
sagaMiddleware.run(sagas);

export default store;
