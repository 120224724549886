import { Dropdown } from "react-bootstrap";
import profile from "../../assets/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Header = () =>{
    return(
        <div className="header d-flex justify-content-space-beetween">
                    <div className="title_section">
                        <Link to={"/"}><FontAwesomeIcon icon={faHome}></FontAwesomeIcon></Link>
                        <h1>Watchtower Group Chat Bot</h1>
                        {/* <p>Excepteur sint occaecat cupidatat non proident.</p> */}
                    </div>
                    <div className="button_sections d-flex justify-content-space-beetween">
                        <button type="button">
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g filter="url(#filter0_d_12_27504)">
                                <rect x="2" y="1" width="46" height="46" rx="23" fill="white"/>
                                <path d="M25 22.52C24.59 22.52 24.25 22.18 24.25 21.77V18.44C24.25 18.03 24.59 17.69 25 17.69C25.41 17.69 25.75 18.03 25.75 18.44V21.77C25.75 22.19 25.41 22.52 25 22.52Z" fill="#344054"/>
                                <path d="M25.02 32.35C22.44 32.35 19.87 31.94 17.42 31.12C16.51 30.82 15.82 30.17 15.52 29.35C15.22 28.53 15.32 27.59 15.81 26.77L17.08 24.65C17.36 24.18 17.61 23.3 17.61 22.75V20.65C17.61 16.56 20.93 13.24 25.02 13.24C29.11 13.24 32.43 16.56 32.43 20.65V22.75C32.43 23.29 32.68 24.18 32.96 24.65L34.23 26.77C34.7 27.55 34.78 28.48 34.47 29.33C34.16 30.18 33.48 30.83 32.62 31.12C30.17 31.95 27.6 32.35 25.02 32.35ZM25.02 14.75C21.76 14.75 19.11 17.4 19.11 20.66V22.76C19.11 23.57 18.79 24.74 18.37 25.43L17.1 27.56C16.84 27.99 16.78 28.45 16.93 28.85C17.08 29.25 17.42 29.55 17.9 29.71C22.5 31.24 27.56 31.24 32.16 29.71C32.59 29.57 32.92 29.25 33.07 28.83C33.23 28.41 33.18 27.95 32.95 27.56L31.68 25.44C31.26 24.75 30.94 23.58 30.94 22.77V20.67C30.93 17.4 28.28 14.75 25.02 14.75Z" fill="#344054"/>
                                <path d="M25 34.9C23.93 34.9 22.88 34.46 22.12 33.7C21.36 32.94 20.92 31.89 20.92 30.82H22.42C22.42 31.5 22.7 32.16 23.18 32.64C23.66 33.12 24.32 33.4 25 33.4C26.42 33.4 27.58 32.24 27.58 30.82H29.08C29.08 33.07 27.25 34.9 25 34.9Z" fill="#344054"/>
                                <rect x="2.5" y="1.5" width="45" height="45" rx="22.5" stroke="#D0D5DD"/>
                                </g>
                                <defs>
                                <filter id="filter0_d_12_27504" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                <feOffset dy="1"/>
                                <feGaussianBlur stdDeviation="1"/>
                                <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"/>
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_12_27504"/>
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_12_27504" result="shape"/>
                                </filter>
                                </defs>
                            </svg>
                        </button>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic">
                                <img src={profile} height={50} width={50}/>
                            </Dropdown.Toggle>

                            {/* <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                            </Dropdown.Menu> */}
                        </Dropdown>
                    </div>
                </div>
    );
}

export default Header;