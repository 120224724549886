import { combineReducers } from 'redux';
import AppReducer, { appStateIF } from './AppReducer';
import ConfigReducer, { confStateIF } from './ConfigReducer';

export interface ReducerStateIF {
    app: appStateIF;
    conf: confStateIF;
}
export default combineReducers({
    // blockchain: blockchainReducer,
    app: AppReducer,
    conf: ConfigReducer
});
