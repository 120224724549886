import Header from "./Header";
import Sidebar from "./Sidebar";

const Template = ({children}:any) =>{
    return(
        <div className="p-3 d-flex min-height-100vh"> 
            <Sidebar/>
            <div className="main_section">
                <Header/>
                {children}
            </div>
        </div>
    );
}

export default Template;