export const INIT_APP = 'INIT_APP';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const LOGOUT = 'LOGOUT';
export const TOKEN_UPDATE = 'TOKEN_UPDATE';
export const LOADING = "LOADING";
export const ACCOUNT_DETAILS = "ACCOUNT_DETAILS";
export const UPDATE_HISTORY = "UPDATE_HISTORY";
export const UPDATE_CHAT_HISTORY = "UPDATE_CHAT_HISTORY";
export const UPDATE_CHAT_TYPE = "UPDATE_CHAT_TYPE";

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';