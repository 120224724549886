import * as types from '../actions/types';

export interface appStateIF {
    authToken: string|null;
}

export const initialState: appStateIF = {
    authToken: null
};

const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case types.INIT_APP:
            return { ...state, ...action.payload };
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                // username: action.payload.username,
                // sessionId: action.payload.sessionId,
                // isAdmin: action.payload.isAdmin
            };
        case types.TOKEN_UPDATE:
            return {
                ...state,
                authToken: action.payload.authToken
            };
        case types.LOGOUT:
            return {
                ...state,
                // username: null,
                // sessionId: null
            };
        default:
            return state;
    }
};

export default reducer;
