import * as types from './types';

const loadingRequest = (payload:any) => {
    return {
        type: types.LOADING,
        payload: payload,
    };
}
  
export const setLoading = (loader: boolean) => {
    return async (dispatch:any) => {
        dispatch(loadingRequest({loader:loader}));
    };
};


const asyncFileHistory = (payload:any) => {
    return {
        type: types.UPDATE_HISTORY,
        payload: payload,
    };
}
  
export const setFileHistoryState = (data:any) => {
    return async (dispatch:any) => {
        dispatch(asyncFileHistory({fileLists:data}));
    };
};

const asyncChatHistory = (payload:any) => {
    return {
        type: types.UPDATE_CHAT_HISTORY,
        payload: payload,
    };
}
  
export const setChatHistoryState = (data:any) => {
    return async (dispatch:any) => {
        dispatch(asyncChatHistory({chatHistory:data}));
    };
};

const updateChatStateType = (payload:any) => {
    return {
        type: types.UPDATE_CHAT_TYPE,
        payload: payload,
    };
}
  
export const setChatStatusType = (type:any) => {
    return async (dispatch:any) => {
        dispatch(updateChatStateType({chatType:type}));
    };
};



// const updateDetailsRequest = (payload:any) => {
//     return {
//         type: types.ACCOUNT_DETAILS,
//         payload: payload,
//     };
// }
  
// export const setAccountDetails = (data:any) => {
//     return async (dispatch:any) => {
//         dispatch(updateDetailsRequest(data));
//     };
// };