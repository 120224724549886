import "./errorpage.css";

const ErrorPage = () =>{

    return(
        <div className="errorDisply"> 
           <p className="errorTitle">Page Not Found</p>
        </div>
    );
}

export default ErrorPage;