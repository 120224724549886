import React, { Suspense } from "react";
import { Route, Routes} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ErrorPage from "../screens/ErrorPage";
import Dashboard from "../screens/Dashboard";
import Template from "../screens/Layout/Template";
import Chat from "../screens/Chat";

const AppMain = () => {
    return (
        <>
            <Suspense fallback={<span>loading</span>}>
                <Template>
                    <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route path="/chat" element={<Chat />} />
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </Template>
            </Suspense>
            <ToastContainer />
        </>
    );
};

export default AppMain;
