import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../../assets/logo.png";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReducerStateIF } from "../../redux/reducers";
import React, { useEffect, useState } from "react";
import { setChatStatusType } from "../../redux/actions/ConfigAction";

const Sidebar = () =>{
    const getChatHistory = useSelector((state:ReducerStateIF) => state.conf.chatHistory);
    const location = useLocation();
    const dispatch:any = useDispatch();
    const navigation = useNavigate();
    const [chatHistory, setChatHistory] = useState<any>({})

    const updateChatState = async(e:any) => {
        e.preventDefault()
        dispatch(setChatStatusType(true))
        navigation('/chat')
    }

    useEffect(()=>{
        setChatHistory(getChatHistory)
    },[getChatHistory])

    return(
        <div className="sidebar">
            <div className="logo text-center mb-5">
                <Link to={"/"}><img src={logo} height={"60px"} /></Link>
            </div>
            {/* {(location.pathname == "/chat")?( */}
                <a href="#" onClick={(e:any) => updateChatState(e)} className="transparentBtn new_chat_btn"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon> &nbsp; New Chat</a>
           
            <div className="history_list_scroll">
                <div className="historyList">
                    {/* {console.log(chatHistory)} */}
                    {chatHistory && Object.keys(chatHistory).map((e:any, yearIndex:number) => (
                        <div key={"year_month_"+yearIndex} className="history_section">
                            <p className="days_title">{e}</p>
                            <ul key={"listing_"+yearIndex}>
                            {chatHistory[e] && Object.keys(chatHistory[e]).map((ee:any, ind:number) => (
                                <React.Fragment key={"first_question_"+ind}>
                                    {chatHistory[e][ee] && chatHistory[e][ee].map((que:any, qi:number) => (
                                        <React.Fragment key={"firstquestion_"+qi}>
                                            {(que[0]) && (
                                                <li key={"question__"+ind}>
                                                    <Link to={"/chat?year="+e+"&date="+ee+"&time="+qi}>
                                                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g filter="url(#filter0_b_18_27953)">
                                                            <rect width="34" height="34" rx="8" fill="white" fillOpacity="0.12"/>
                                                            <path d="M13.8402 21.5933C13.7868 21.5933 13.7335 21.5866 13.6802 21.58C13.2802 21.5266 12.9402 21.2733 12.7735 20.9133L11.9335 19.0666C10.6002 18.0799 9.8335 16.6066 9.8335 15.0066C9.8335 12.16 12.3002 9.83997 15.3335 9.83997C17.4735 9.83997 19.4268 11.0133 20.3135 12.8333C20.6535 13.5066 20.8335 14.24 20.8335 15.0066C20.8335 17.78 18.4935 20.0533 15.5735 20.1666L14.7335 21.18C14.5068 21.44 14.1802 21.5933 13.8402 21.5933ZM15.3335 10.8333C12.8535 10.8333 10.8335 12.7 10.8335 15C10.8335 16.3133 11.4868 17.5266 12.6202 18.3266C12.6935 18.38 12.7535 18.4466 12.7868 18.5266L13.6802 20.4933C13.7135 20.5666 13.7802 20.5866 13.8135 20.5866C13.8468 20.5933 13.9135 20.5866 13.9602 20.5333L14.9468 19.3466C15.0402 19.2333 15.1802 19.1666 15.3335 19.1666C17.8135 19.1666 19.8335 17.3 19.8335 15C19.8335 14.3933 19.6935 13.8133 19.4202 13.2733C18.7002 11.7866 17.0935 10.8333 15.3335 10.8333Z" fill="white"/>
                                                            <path d="M20.1602 24.1667C19.8136 24.1667 19.4935 24.0134 19.2669 23.7467L18.4269 22.74C16.8135 22.6734 15.2935 21.9333 14.3202 20.7333C14.1669 20.5467 14.1736 20.28 14.3269 20.1L14.9469 19.3534C15.0402 19.2401 15.1802 19.1734 15.3336 19.1734C17.8136 19.1734 19.8336 17.3067 19.8336 15.0067C19.8336 14.4 19.6936 13.82 19.4202 13.28C19.3336 13.1067 19.3536 12.9 19.4736 12.7467C19.5936 12.5934 19.7936 12.5267 19.9802 12.5667C22.4469 13.1333 24.1669 15.1934 24.1669 17.58C24.1669 19.18 23.4002 20.6534 22.0669 21.64L21.2269 23.4867C21.0535 23.86 20.7136 24.1134 20.3069 24.16C20.2602 24.1667 20.2069 24.1667 20.1602 24.1667ZM15.3735 20.4C16.2069 21.2467 17.4069 21.74 18.6669 21.74C18.8136 21.74 18.9536 21.8067 19.0536 21.92L20.0402 23.1067C20.0869 23.1667 20.1469 23.1734 20.1869 23.1667C20.2135 23.1667 20.2869 23.1467 20.3269 23.0667L21.2202 21.1C21.2602 21.02 21.3136 20.9467 21.3869 20.9C22.5202 20.1 23.1735 18.8867 23.1735 17.5734C23.1735 15.9734 22.1936 14.5534 20.7002 13.8534C20.7936 14.2267 20.8402 14.6067 20.8402 15C20.8402 17.7734 18.5002 20.0467 15.5802 20.16L15.3735 20.4Z" fill="white"/>
                                                            <path d="M16.9998 15.5H13.6665C13.3932 15.5 13.1665 15.2733 13.1665 15C13.1665 14.7267 13.3932 14.5 13.6665 14.5H16.9998C17.2732 14.5 17.4998 14.7267 17.4998 15C17.4998 15.2733 17.2732 15.5 16.9998 15.5Z" fill="white"/>
                                                            </g>
                                                            <defs>
                                                            <filter id="filter0_b_18_27953" x="-44" y="-44" width="122" height="122" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                            <feGaussianBlur in="BackgroundImageFix" stdDeviation="22"/>
                                                            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_18_27953"/>
                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_18_27953" result="shape"/>
                                                            </filter>
                                                            </defs>
                                                        </svg>
                                                        <p>{que[0].question}</p>
                                                    </Link>
                                                    </li>
                                            )}
                                        </React.Fragment>     
                                    ))}
                                </React.Fragment>
                            ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Sidebar;