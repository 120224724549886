import axios from 'axios'
import { logout } from '../actions/AuthAction';
import { setLoading } from '../actions/ConfigAction';
// import { ReducerStateIF } from '../reducers';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import store from '../store';
import { BASE_URI } from '../../config/constant';


export const get = (path: string, params?: any, dispatch?:any) => {
    return new Promise((resolve, reject) => {
        try {
            const storages = store.getState()
            // axios.defaults.baseURL = "https://flask-bank-webapp-service.azurewebsites.net/";
            axios.defaults.baseURL = BASE_URI;
            
            let config:any = {
               headers: { 
                  'Authorization': storages.app.authToken, 
                  'Authorization_H': storages.app.authToken,
                },
                data: params
            };

            axios.get(path, config).then(function(getResponse:any){
                return getResponse.data;
            })
            .then(resolve)
            .catch(function(e:any){
                reject(e)
            });
        } catch (error) {
            reject(error);
        }
    });
};

/**
 * Post request
 * @param path      API url path
 * @param params    Request parameters
 */
export const post = async(path: string, params?: any, dispatch?:any, contntype?:string) => {
    return new Promise((resolve, reject) => {
        try {
            
            const storages = store.getState()
            // axios.defaults.baseURL = "https://flask-bank-webapp-service.azurewebsites.net/";
            axios.defaults.baseURL = BASE_URI;
            
            let config:any = {
                headers: { 
                  'Authorization': storages.app.authToken,
                  'Authorization_H': storages.app.authToken,
                }
            };

            if(contntype === 'file'){
                config.headers['Content-Type'] = 'multipart/form-data'
            }else if(contntype === 'text'){
                config.headers['Content-Type'] = 'text/plain'
            }else if(contntype === 'json'){
                config.headers['Content-Type'] = 'application/json'
            }

            axios.post(path, params, config)
            .then(function(getResponse:any){
                return getResponse.data;
            })
            .then(resolve)
            .catch(function(e:any){
                reject(e)
            });
        } catch (error) {
            // dispatch(setLoading(false))
            dispatch(setLoading(false));
            reject(error);
        }
    });
};

export default { get, post };
